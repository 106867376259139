import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// header
import { headerParams } from 'src/utils/headers';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: false,
    error: null,
    empty: false,
    jobPreview: null,
}

const slice = createSlice({
    name: 'individualIntJob',
    initialState,
    reducers: {
        startLoading(state, action) {
            state.loading = true;
            state.jobPreview = null;
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload.data;
        },
        jobPrviewSuccess(state, action) {
            state.loading = false;
            state.error = null;
            state.jobPreview = action.payload;
        },
        resetStateFetch(state, action) {
            state.loading = false;
            state.jobPreview = null;
            state.error = null
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { jobPrviewSuccess, } = slice.actions;

export function getIntIndividualJob(token, jobId, jobType) {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
        dispatch(slice.actions.startLoading());
        const BaseURL = jobType && jobType === "INTERNAL" ? DomainURL.BaseUrl : DomainURL.BaseExternalURL
        try {
            const response = await axios.get(`${BaseURL}candidate/job/${jobId}`, { headers: headerParams(token) });
            dispatch(slice.actions.jobPrviewSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function resetIndividualJob() {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
    }
}